*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

:is(::-webkit-inner-spin-button, ::-webkit-outer-spin-button) {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (min-width: 400px) {
  .container {
    max-width: 400px;
  }
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.m-1 {
  margin: .25rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-3 {
  margin-top: .75rem;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.min-h-screen {
  min-height: 100vh;
}

.grow {
  flex-grow: 1;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.gap-4 {
  gap: 1rem;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-lg {
  border-radius: .5rem;
}

.border-2 {
  border-width: 2px;
}

.border-slate-800 {
  --tw-border-opacity: 1;
  border-color: #1e293b;
  border-color: rgb(30 41 59 / var(--tw-border-opacity));
}

.bg-black\/10 {
  background-color: #0000001a;
}

.bg-sky-100 {
  --tw-bg-opacity: 1;
  background-color: #e0f2fe;
  background-color: rgb(224 242 254 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: #fff;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-gradient-to-t {
  background-image: linear-gradient(to top, var(--tw-gradient-stops));
}

.bg-gradient-to-tr {
  background-image: linear-gradient(to top right, var(--tw-gradient-stops));
}

.from-purple-200 {
  --tw-gradient-from: #e9d5ff var(--tw-gradient-from-position);
  --tw-gradient-to: #e9d5ff00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-yellow-100 {
  --tw-gradient-from: #fef9c3 var(--tw-gradient-from-position);
  --tw-gradient-to: #fef9c300 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.to-emerald-300 {
  --tw-gradient-to: #6ee7b7 var(--tw-gradient-to-position);
}

.to-sky-100 {
  --tw-gradient-to: #e0f2fe var(--tw-gradient-to-position);
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.p-2 {
  padding: .5rem;
}

.p-5 {
  padding: 1.25rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pt-1 {
  padding-top: .25rem;
}

.text-center {
  text-align: center;
}

.align-text-top {
  vertical-align: text-top;
}

.font-sans {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-\[0\.8rem\] {
  font-size: .8rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-light {
  font-weight: 300;
}

.font-thin {
  font-weight: 100;
}

.italic {
  font-style: italic;
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.backdrop-blur-xl {
  --tw-backdrop-blur: blur(24px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.animate-scrolling-loop:hover {
  --stop-0: #e9d5ff;
  --stop-1: #6ee7b7;
  background: #e9d5ff;
  background: var(--stop-0);
  background: -webkit-linear-gradient(45deg, #e9d5ff 0%, #6ee7b7 33%, #e9d5ff 66%, #6ee7b7 100%);
  background: -webkit-linear-gradient(45deg, var(--stop-0) 0%, var(--stop-1) 33%, var(--stop-0) 66%, var(--stop-1) 100%);
  background: -moz-linear-gradient(45deg, #e9d5ff 0%, #6ee7b7 33%, #e9d5ff 66%, #6ee7b7 100%);
  background: -moz-linear-gradient(45deg, var(--stop-0) 0%, var(--stop-1) 33%, var(--stop-0) 66%, var(--stop-1) 100%);
  background: -ms-linear-gradient(45deg, #e9d5ff 0%, #6ee7b7 33%, #e9d5ff 66%, #6ee7b7 100%);
  background: -ms-linear-gradient(45deg, var(--stop-0) 0%, var(--stop-1) 33%, var(--stop-0) 66%, var(--stop-1) 100%);
  background: -o-linear-gradient(45deg, #e9d5ff 0%, #6ee7b7 33%, #e9d5ff 66%, #6ee7b7 100%);
  background: -o-linear-gradient(45deg, var(--stop-0) 0%, var(--stop-1) 33%, var(--stop-0) 66%, var(--stop-1) 100%);
  background: linear-gradient(45deg, #e9d5ff 0%, #6ee7b7 33%, #e9d5ff 66%, #6ee7b7 100%);
  background: linear-gradient(45deg, var(--stop-0) 0%, var(--stop-1) 33%, var(--stop-0) 66%, var(--stop-1) 100%);
  background-size: 400% 400%;
  animation: 2s linear infinite splitKeyframes;
}

@keyframes splitKeyframes {
  0% {
    background-position: 100% 0;
  }

  100% {
    background-position: 15% 100%;
  }
}

.hover\:bg-sky-50:hover {
  --tw-bg-opacity: 1;
  background-color: #f0f9ff;
  background-color: rgb(240 249 255 / var(--tw-bg-opacity));
}

.hover\:font-semibold:hover {
  font-weight: 600;
}

@media (min-width: 400px) {
  .xs\:mt-2 {
    margin-top: .5rem;
  }

  .xs\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .xs\:bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: #d8b4fe;
    background-color: rgb(216 180 254 / var(--tw-bg-opacity));
  }
}

@media (min-width: 640px) {
  .sm\:mt-3 {
    margin-top: .75rem;
  }

  .sm\:bg-lime-200 {
    --tw-bg-opacity: 1;
    background-color: #d9f99d;
    background-color: rgb(217 249 157 / var(--tw-bg-opacity));
  }
}

@media (min-width: 768px) {
  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .md\:bg-lime-900 {
    --tw-bg-opacity: 1;
    background-color: #365314;
    background-color: rgb(54 83 20 / var(--tw-bg-opacity));
  }

  .md\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

@media (min-width: 1024px) {
  .lg\:bg-amber-500 {
    --tw-bg-opacity: 1;
    background-color: #f59e0b;
    background-color: rgb(245 158 11 / var(--tw-bg-opacity));
  }
}

@media (min-width: 1280px) {
  .xl\:bg-amber-950 {
    --tw-bg-opacity: 1;
    background-color: #451a03;
    background-color: rgb(69 26 3 / var(--tw-bg-opacity));
  }
}

@media (min-width: 1536px) {
  .\32 xl\:bg-red-950 {
    --tw-bg-opacity: 1;
    background-color: #450a0a;
    background-color: rgb(69 10 10 / var(--tw-bg-opacity));
  }
}

/*# sourceMappingURL=index.8866062a.css.map */
