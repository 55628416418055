@tailwind base;
@tailwind components;
@tailwind utilities;

.animate-scrolling-loop:hover {
  --stop-0: rgb(233 213 255);
  --stop-1: rgb(110 231 183);
  background: var(--stop-0);
  background: -webkit-linear-gradient(45deg, var(--stop-0) 0%, var(--stop-1) 33%, var(--stop-0) 66%, var(--stop-1) 100%);
  background: -moz-linear-gradient(45deg, var(--stop-0) 0%, var(--stop-1) 33%, var(--stop-0) 66%, var(--stop-1) 100%);
  background: -ms-linear-gradient(45deg, var(--stop-0) 0%, var(--stop-1) 33%, var(--stop-0) 66%, var(--stop-1) 100%);
  background: -o-linear-gradient(45deg, var(--stop-0) 0%, var(--stop-1) 33%, var(--stop-0) 66%, var(--stop-1) 100%);
  background: linear-gradient(45deg, var(--stop-0) 0%, var(--stop-1) 33%, var(--stop-0) 66%, var(--stop-1) 100%);
  background-size: 400% 400%;
  -webkit-animation: splitKeyframes 2s linear infinite;
  -moz-animation: splitKeyframes 2s linear infinite;
  animation: splitKeyframes 2s linear infinite;
}

@-webkit-keyframes splitKeyframes {
  0% {
    background-position: 100% 0%;
  }

  100% {
    background-position: 15% 100%;
  }
}

@-moz-keyframes splitKeyframes {
  0% {
    background-position: 100% 0%;
  }

  100% {
    background-position: 15% 100%;
  }
}

@keyframes splitKeyframes {
  0% {
    background-position: 100% 0%;
  }

  100% {
    background-position: 15% 100%;
  }
}

/* Saved ideas:

onclick ripple effect:
block py-2 pr-2  transition duration-150 ease-in-out hover:text-neutral-700 focus:text-neutral-700 dark:hover:text-white dark:focus:text-white lg:px-2



*/